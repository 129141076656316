import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import * as S from './styled';
import TitlePage from '../../components/TitlePage';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import PageImage from '../../components/PageImage';

const ThanksContent = () => {
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const homePage = locale === 'en' ? '/en/' : '/';

  setTimeout(() => {
    navigate(homePage);
  }, 5000);
  return (
    <WrapperPage id="thanks">
      <PageImage>
        <StaticImage
          src="../../assets/images/hands.jpg"
          alt="Polityka prywatności"
          style={{ width: '100%', height: '100%', opacity: 0.2 }}
          layout="fullWidth"
          objectPosition="50% 75%"
          quality={85}
          imgStyle={{
            filter: 'grayscale()',
          }}
        />
      </PageImage>
      <WrapperSection>
        <S.Wrapper id="policy">
          <S.Container>
            <TitlePage
              text={t('thanks')}
              subtitle={t('messageSend')}
            />
            <S.DescriptionContainer>
              <S.Description>
                {t('thanksOnForm')}
                <br />
                <br />
                {t('regards')} <br />
                Grzegorz Domaradzki
              </S.Description>
            </S.DescriptionContainer>
          </S.Container>
        </S.Wrapper>
      </WrapperSection>
    </WrapperPage>
  );
};

export default ThanksContent;
