import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  user-select: none;
  ${media.greaterThan('medium')`
  `}
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 10px;
  ${media.greaterThan('large')`
    max-width:1170px;

  `}
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  padding: 5px;
  user-select: none;
  ${media.greaterThan('medium')`
    padding: 20px;
  `}
  ${media.greaterThan('large')`
    padding: 50px;
  `}
`;

export const Description = styled.p`
  font-family: var(--font-open);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  color: var(--primary-color);
  white-space: pre-line;
  ${media.greaterThan('medium')`
    font-size: 20px;
    margin-top:20px
  `}
`;
